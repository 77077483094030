import Quill from "quill";
import { ImageHandler, AttachmentHandler } from "quill-upload";

// register quill-upload
Quill.register("modules/imageHandler", ImageHandler);
Quill.register("modules/attachmentHandler", AttachmentHandler);

window.createQuill = function(selector) {
  const fullToolbarOptions = [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['link'],
    ['image', 'attachment']
  ];

  const editor = new Quill(selector, {
    theme: 'snow',
    modules: {
      toolbar: {
        container: fullToolbarOptions,
      },
      imageHandler: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("image", file);

            fetch(
              "/admin/quills/upload_image",
              {
                method: "POST",
                body: formData
              }
            )
            .then((response) => response.json())
            .then((result) => {
              resolve(result.url);
            })
            .catch((error) => {
              reject("Upload failed");
              console.error("Error:", error);
            });
          });
        }
      },
      attachmentHandler: {
        upload: file => {
          // return a Promise that resolves in a link to the uploaded image
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", file);

            fetch(
              "/admin/quills/upload_file",
              {
                method: "POST",
                body: formData
              }
            )
            .then((response) => response.json())
            .then((result) => {
              resolve(result.url);
            })
            .catch((error) => {
              reject("Upload failed");
              console.error("Error:", error);
            });
          })
        }
      },
    },
  });

  return editor;
}
